.date {
  margin: 12px 5px 10px;
  font-family: "Oswald", sans-serif;
  p {
    margin: 0 10px;
    font-family: "Oswald", sans-serif;
  }
}

.new_item {
  margin-bottom: 30px;
  .new_item--image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-size: contain;
  }
}
.new_item:hover {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: ease 0.5s;
}

.page-controller {
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.page-icon-button,
.page-button-no-outline,
.page-button-solid {
  font-weight: 500;
  font-size: 20px;
  min-width: 30px;
  margin-right: 10px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30px;
}

.page-icon-button--left {
  text-align: right;
}

.page-icon-button--right {
  text-align: left;
}

.page-icon-button img {
  width: 10px;
}

.page-button-solid--primary {
  background-color: #606637;
  color: white;
}

.page-button-no-outline:hover {
  color: #606637;
}

@import "antd.scss";
