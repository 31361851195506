.categories_wrapper {
  margin: 20px;
}

.thumbnail_wrapper{
  margin-bottom: 20px;
    .thumbnail{
      width: 200px;
      position: relative;
      .after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        .delete_image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: 0;
          svg{
            color: white;
          }
          
        }
      }
    }
    .thumbnail:hover{
      .after{
        display: block;
        background-color: rgba(0, 0, 0, 0.4);
        .delete_image{
          display: block;
        }
      }
    }
}
