@font-face {
  src: url("../../assets/font/laoscitechFont.otf");
  font-family: "Terminator Real NFI";
}

.header_top {
  margin-left: 10px;
  margin: var(--deafault-layout-margin);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.logo {
  // padding: 22px 34px;
  display: flex;
  align-items: center;
  widows: 70px;
  height: 50px;
  background-color: #d9d9d9;

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    a {
      color: #000;
    }
  }
}

.language_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.language_E,
.language_L {
  display: flex;

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Oswald", sans-serif;
  }
}

.divide_lan {
  display: flex;
  align-items: center;
  width: 2px;
  background-color: var(--primary-color);
  margin-left: 10px;
  margin-right: 10px;
  height: 16px;
}

/// Header Bottom
.menu_mobile {
  display: none;
  font-size: 30px;
  padding: 0 23px;
  background-color: #3d4028;
  color: white;
}

.header_bottom {
  display: flex;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: var(--deafault-layout-padding);
}

.phone_number_container {
  display: inline-flex;
  transition: all 0.3s;
  flex: 2;
  @keyframes grow {
    from {
      transform: scaleX(1);
    }

    to {
      transform: scaleX(0.4);
    }
  }

  // flex-grow: 1;
  transform-origin: left;
  animation: grow 0.3s linear;
  align-items: center;
}

.navbar_header {
  flex: 1;
  border-left: 1px solid var(--primary-color);
  height: 70px;
  padding: 0 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  position: relative;
  color: var(--primary-color);
  text-transform: uppercase;
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }

  // border-top: 2px solid var(--primary-color);
  // border-bottom: 2px solid var(--primary-color);
  a,
  ul {
    color: var(--primary-color);
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
      color: black;
    }
  }

  ul {
    display: flex;
    width: 100%;
    padding: 0;
  }

  li {
    list-style: none;
    color: var(--primary-color);
    padding: 20px 30px;
    border-bottom: 1px solid var(--primary-color);

    &:last-child {
      border-bottom: 0 solid var(--primary-color);
    }

    &:hover {
      background-color: var(--primary-color);
      color: white;
      cursor: pointer;
    }
  }

  .icon_sub_menu {
    margin-left: 7px;

    svg {
      transform: rotate(180deg);
    }
  }

  .sub_menu {
    position: absolute;
    box-shadow: 1px 1px 1px 1px;
    background-color: #fff;
    display: flex;
    z-index: 9;
    flex-direction: column;
    text-align: center;
    top: 70px;
    left: 0;
    width: 100%;
    box-shadow: var(
      --ds-shadow-overlay,
      0 4px 8px -2px rgba(9, 30, 66, 0.25),
      0 0 1px rgba(9, 30, 66, 0.31)
    );
  }

  &.phone_icon {
    flex: 0;
    width: 74px;
    background-color: var(--primary-color);
    font-size: 30px;
    color: #fff;

    svg {
      transform: rotate(-30deg);
    }
  }

  &.search_icon {
    flex: 0;
    width: 126px;
    border-right: 1px solid var(--primary-color);
    // margin-left: auto;
    cursor: pointer;

    div {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 300;
    }
  }

  &.search-container {
    flex: 2;
    // flex-grow: 1;
    .search_input {
      .icon-container {
        margin-right: 22px;
      }
    }
    @keyframes grow {
      from {
        transform: scaleX(0.4);
      }

      to {
        transform: scaleX(1);
      }
    }

    flex-grow: 2;
    transform-origin: right;
    animation: grow 0.3s linear;
    padding: 0;

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      padding: 0 23px;

      &::placeholder {
        opacity: 0.4;
      }
    }
  }
}
.navbar_headermb {
  border-left: 1px solid var(--primary-color);
  height: 70px;
  padding: 0 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
  position: relative;
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }

  // border-top: 2px solid var(--primary-color);
  // border-bottom: 2px solid var(--primary-color);
  a,
  ul {
    color: var(--primary-color);
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
      color: black;
    }
  }

  ul {
    display: flex;
    width: 100%;
    padding: 0;
  }

  li {
    list-style: none;
    color: var(--primary-color);
    padding: 20px 30px;
    border-bottom: 1px solid var(--primary-color);

    &:last-child {
      border-bottom: 0 solid var(--primary-color);
    }

    &:hover {
      background-color: var(--primary-color);
      color: white;
      cursor: pointer;
    }
  }

  .icon_sub_menu {
    margin-left: 7px;

    svg {
      transform: rotate(180deg);
    }
  }

  .sub_menu {
    position: absolute;
    box-shadow: 1px 1px 1px 1px;
    background-color: #fff;
    display: flex;
    z-index: 9;
    flex-direction: column;
    text-align: center;
    top: 70px;
    left: 0;
  }

  &.phone_icon {
    width: 74px;
    background-color: var(--primary-color);
    font-size: 30px;
    color: #fff;

    svg {
      transform: rotate(-30deg);
    }
  }

  &.search_iconmb {
    // width: 126px;
    border-right: 1px solid var(--primary-color);
    // margin-left: auto;
    cursor: pointer;

    div {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 300;
    }
  }

  &.search-container {
    // flex-grow: 1;

    @keyframes grow {
      from {
        transform: scaleX(0.4);
      }

      to {
        transform: scaleX(1);
      }
    }

    flex-grow: 1;
    transform-origin: right;
    animation: grow 0.3s linear;

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;

      &::placeholder {
        opacity: 0.4;
      }
    }
  }
}
.navbar_select {
  background-color: var(--primary-color);

  ul {
    color: white;

    &:hover {
      color: white;
    }
  }

  .icon_sub_menu {
    margin-left: 7px;

    svg {
      transform: rotate(0);
    }
  }
}

@import "HeaderResponsive";
