.news_image {
  margin-bottom: 20px;
  width: 100%;
  img {
    object-fit: contain;
    width: 500px;
  }
}

.date {
  margin: 12px 5px 10px;
  p {
    margin: 0 10px;
    font-family: "Oswald", sans-serif;
  }
}

@import "replace.scss";
