@font-face {
  src: url("../../../assets/font/laoscitechFont.otf");
  font-family: "Terminator Real NFI";
}
.custom-clss {
  overflow: hidden;
}
.wrapper-item {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  height: 400px;
  .category-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .box-function {
      @keyframes decline {
        from {
          height: 100%;
        }
        to {
          height: 20%;
        }
      }
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 20%;
      background-color: rgba(149, 155, 105, 0.7);
      animation: decline 0.5s linear;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;
        color: white;
        display: block;
        white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        margin-bottom: 0;
        font-family: "Oswald", sans-serif;
        padding: 0 10px;
        animation: scrolling 5s linear infinite;
      }
      // @keyframes scrolling {
      //   from {
      //     transform: translateX(70%);
      //   }
      //   to {
      //     transform: translateX(-100%);
      //   }
      // }
    }
    &:hover {
      .box-function {
        @keyframes grow {
          from {
            height: 20%;
          }
          to {
            height: 100%;
          }
        }
        height: 100%;
        transform-origin: bottom;
        animation: grow 0.5s linear;
        text-align: center;
      }
    }
  }
}

// .wrapper {
//   .main_title {
//     .custom-family {
//       font-weight: 100;
//       font-family: "Terminator Real NFI";
//     }
//   }
// }

@import "ResponsiveMain-category.scss";
