.warpper_search {
  margin: 60px 90px;
  .header_search {
    p {
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 63px;
      text-transform: uppercase;
      color: #464932;
      span {
        text-transform: initial;
      }
    }
  }
  .result_search {
    justify-content: space-between;
    // margin: 0 40px;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;

    .notData {
      min-height: 150px;
    }
    // justify-content: space-between;

    .product_search {
      height: 230px;
      cursor: pointer;
      margin-bottom: 100px;
      .items_search {
        .img_search {
          img {
            width: 414px;
            height: 230px;
            width: 100%;
            object-fit: cover;
          }
        }
        p {
          font-family: "Saysettha";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 38px;
          color: #f4f5f6;
          text-align: center;
          text-transform: uppercase;
          background: #606637;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 45px;
        }
      }
    }
  }
}
@import "./Search.responsive.scss";
