.product-list-container {
  .ant-tabs-tab {
    cursor: pointer;
    // margin-left: 10px;
    // border: 2px solid var(--primary-color);
    border: 0;
    margin-bottom: 0;
    margin-top: 7px;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    // padding-right: 60px;
    // background-color: var(--primary-color);
    color: #fff;

    &:first-child {
      // margin-left: 0px;
      // background-color: #494d1e;
    }

    &:nth-child(4) {
      // margin-left: 0px !important;
      // background-color: #494d1e;
    }
    &:hover {
      color: #fff;
    }
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 90px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    // margin-left: 30px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 12px;
  }
  .ant-tabs-tab-active {
    background-color: #fff !important;
    .ProductList_item_parent__gehHk.ProductList_classParent__8orqL {
      background-color: #fff !important;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      a {
        color: var(--primary-color) !important;
      }
    }
    .ProductList_item_parent__gehHk {
      background-color: #fff !important;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      a {
        color: var(--primary-color) !important;
      }
    }
    .ProductList_item_parent__NeTTE.ProductList_classParent__D4PA8 {
      background-color: #fff !important;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      a {
        color: var(--primary-color) !important;
      }
    }
    .ProductList_item_parent__NeTTE {
      background-color: #fff !important;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      a {
        color: var(--primary-color) !important;
      }
    }
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    p {
      color: var(--primary-color) !important;
    }
    // color: red;
    background-color: #fff !important;
  }
  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    height: 0;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: 0;
    border-left: 0px solid #f0f0f0;
    ul.ant-pagination {
      display: inline-flex;
      position: absolute;
      right: 20px;
      margin-top: 10px;
    }
    .loading_replace {
      margin: -50px 500px;
    }
  }
  @media (max-width: 767px) {
    .ant-tabs.ant-tabs-left {
      flex-direction: column;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
      .loading_replace {
        margin: 0;
      }
    }

    .ant-tabs-left
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding-left: 0;
    }
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
      .loading_replace {
        margin: -80px 280px;
      }
    }
    .ant-tabs-left
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  li.ant-pagination-item {
    border: 0;
    background-color: #fff;
    color: #606637;
  }
  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    color: #fff;
    z-index: 1;
  }
  button.ant-pagination-item-link {
    border: 0;
  }
  .ant-pagination-options {
    display: none;
  }
}
