@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper_product_list_milatary {
    height: 100%;
    .body_prodList {
      .product_imgShow {
        width: 415px;
        /* width: 30%; */
        margin-left: 55px;
      }
      .product {
        flex-direction: column;
        .milatary_content {
          width: 30%;
        }
        .product_img {
          margin-bottom: 60px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper_product_list_milatary {
    height: 100%;
    .body_prodList {
      .product_imgShow {
        width: 300px;
        /* width: 30%; */
        // margin-left: 55px;
        margin-top: 30px;
      }
      .product {
        flex-direction: column;
        margin-top: 30px;
        margin-left: 0;
        width: initial;
        .milatary_content {
          // width: 30%;
        }
        .product_img {
          width: initial;
          margin-bottom: 60px;
          margin-left: 0;
          &:first-child {
            margin-left: 0;
          }
        }
        p {
          width: initial;
        }
      }
    }
  }
}
