.product-list-milatary-container {
  .ant-tabs-tab {
    cursor: pointer;

    border: 2px solid var(--primary-color);
    margin-bottom: 0;
    margin-top: 7px;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding-right: 60px;
    background-color: var(--primary-color);
    color: #fff;
    margin-left: 20px !important;

    &:first-child {
      margin-left: 0px !important;
      background-color: #494d1e;
    }
    &:hover {
      color: #fff;
    }
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 12px;
    padding-right: 150px;
  }
  .ant-tabs-tab-active {
    background-color: #fff !important;
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
  }
  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    height: 0;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: 0;
    border-left: 0px solid #f0f0f0;
    ul.ant-pagination {
      display: inline-flex;
      position: absolute;
      right: 20px;
      margin-top: 10px;
    }
    .loading_replace {
      margin: -50px 500px;
    }
  }

  li.ant-pagination-item {
    border: 0;
    background-color: #fff;
    color: #606637;
  }
  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    color: #fff;
  }
  button.ant-pagination-item-link {
    border: 0;
  }
  .ant-pagination-options {
    display: none;
  }
  @media (max-width: 767px) {
    .ant-tabs.ant-tabs-left {
      flex-direction: column;
    }
    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
      .loading_replace {
        margin: 0;
      }
    }

    .ant-tabs-left
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding-left: 0;
    }
  }
  @media (max-width: 767px) and (min-width: 1024px) {
    // .ant-tabs.ant-tabs-left {
    //   flex-direction: column;
    // }

    .ant-tabs-left > .ant-tabs-content-holder,
    .ant-tabs-left > div > .ant-tabs-content-holder {
      .loading_replace {
        margin: -80px 280px;
      }
    }
  }
}
