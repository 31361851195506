@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}

@font-face {
  src: url("../../../assets/font/laoscitechFont.otf");
  font-family: "Terminator Real NFI";
}
.wrapper_home {
  .home_tank {
    padding: var(--deafault-layout-padding);
    display: flex;
    height: 640px;
    padding-top: 55px;
    background: linear-gradient(
      180deg,
      #b1a96e 0%,
      #b1a96e 52.08%,
      rgba(177, 169, 110, 0) 100%
    );
    .home_infor_detail {
      width: 35%;
      .tittle_infor {
        display: flex;
        font-weight: 700;
        font-size: 40px;
        line-height: 54px;
        text-align: center;
        align-items: center;
        justify-content: center;
        h2 {
          padding-top: 35px;
          color: #fff;
          text-transform: uppercase;
          font-family: "Oswald", sans-serif;
          font-weight: 400;
        }
      }
      .wrapper_table {
        //   width: 413px;

        .table_infor {
          background: #606637;
          padding: 40px 50px;
          display: flex;

          flex-direction: column;
          // text-align: center;
          .infor_detail {
            display: flex;
            padding: 3px 0;
            text-align: center;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            border-bottom: 1px solid #fff;
            justify-content: space-between;
            div {
              display: flex;
              p {
                display: inline-block;
                text-align: left;
                // width: 245px;
                min-width: 55px;
                color: #fff;

                margin-bottom: 0;
                font-weight: 200;
                font-family: "Oswald", sans-serif;

                &:nth-child(2) {
                  padding: 5px 18px;
                }
              }
            }
            &:last-child {
              border-bottom: 0px solid #fff;
            }
            img {
              width: 22.91px;
              height: 18px;
              margin-left: 7px;
              margin-right: 20px;
            }
            // p {

            // }
            span {
              color: #fff;
              padding-left: 15px;
              margin-bottom: 0;
              margin-left: 5px;
              border-left: 1px solid #fff;
              font-family: "Saysettha";
              min-width: 70px;
            }
          }
        }
        .home_btn {
          height: 56px;
          background-color: #fff;
          color: var(--primary-color);
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-top: 12px;
          font-family: "Oswald", sans-serif;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: #606637;
            p {
              color: #fff;
            }
          }
          p {
            color: var(--primary-color);

            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 0;
          }
        }
      }
    }
    .home_img {
      flex: 1;
      position: relative;
      img {
        object-fit: cover;
        position: absolute;
        // image-rendering: pixelated;
        top: 80px;
        right: 0px;
        height: 396px;
        width: 738px;
      }
    }
  }
  .home_product {
    padding: var(--deafault-layout-padding);
    height: 444px;
    .homeproduct_header {
      display: flex;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      margin-top: 60px;
      p {
        font-weight: 700;
        font-size: 40px;
        line-height: 63px;
        font-family: "Saysettha";
        color: var(--primary-color);
      }
      .custom_font_family {
        font-weight: 400;
        font-family: "Oswald", sans-serif;
      }
    }
    .homeproduct_selected {
      display: flex;
      text-align: center;
      justify-content: space-around;
      margin-top: 30px;
      .homeproduct_detail {
        .background_img {
          background-image: url("https://laoscitec.api.minastik.com/public/media/images/1701852507550-Anh bia edu.png");
          position: relative;
          height: 230px;
          width: 413px;

          .homeproduct_title {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgba(149, 155, 105, 0.7);
            height: 54px;
            transition: height 200ms ease-in;

            p {
              cursor: pointer;
              height: 50%;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              text-align: center;
              justify-content: center;
              font-weight: 500;
              font-size: 24px;
              line-height: 38px;
              text-transform: uppercase;
              color: white;
              font-weight: 300;
              font-family: "Oswald", sans-serif;
            }
          }
        }
        .laboratory {
          // background-image: url("../../../assets/images/HomePage/labortary.png");
          // height: 100%;

          /* Center and scale the image nicely */
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .milatary {
          background-image: url("../../../assets/images/HomePage/milatary.png");
        }
        &:hover {
          .homeproduct_title {
            height: 100%;
          }
        }
      }
    }
  }
  .home_about {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    object-fit: cover;
    gap: 30px;
    padding: 40px;
    background: linear-gradient(
      0deg,
      rgba(35, 39, 8, 0.5),
      rgba(35, 39, 8, 0.5)
    );
    .home_about_bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .homeabout_title_wrapp {
      display: flex;
      text-align: center;
      justify-content: center;
      .homeabout_title {
        width: 100%;
        color: white;
        font-weight: 100;
        font-size: 40px;
        line-height: 39px;
        text-transform: uppercase;
        padding: 0 5px;
        font-family: "Oswald", sans-serif;
      }
    }

    .homeabout_btn_wrapp {
      display: flex;
      text-align: center;
      justify-content: center;

      .homeHover {
        .homeabout_btn {
          padding: 20px;
          font-weight: 500;
          font-size: 24px;
          line-height: 38px;
          background: #b1a96e;
          display: inline-block;
          height: 78px;
          overflow: hidden;

          color: white;
          text-transform: uppercase;
          position: relative;
          color: #232708;
          font-family: "Oswald", sans-serif;

          @-webkit-keyframes shine {
            100% {
              left: 100%;
            }
          }
          @keyframes shine {
            100% {
              left: 100%;
            }
          }

          &::before {
            position: absolute;
            top: 0;
            left: -75%;
            z-index: 2;
            display: block;
            overflow: hidden;
            content: "";
            width: 20%;
            height: 100%;
            background: -webkit-linear-gradient(
              left,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.8) 100%
            );
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.8) 100%
            );
            -webkit-transform: skewX(-25deg);
            transform: skewX(-25deg);
            -webkit-animation: shine 2s infinite ease-in-out;
            animation: shine 2s infinite ease-in-out;
          }
          &:hover:before {
            -webkit-animation: shine 0.7s;
            animation: shine 0.7s;
          }
          .icon_about {
            margin-left: 10px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .home_daily_new {
    padding: var(--deafault-layout-padding);
    height: 700px;
    .home_daily_header {
      display: flex;
      text-align: center;
      justify-content: center;
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 63px;
      text-transform: uppercase;
      margin-top: 60px;
      color: #606637;
      font-family: "Oswald", sans-serif;
    }
    .home_daily_content {
      display: flex;
      margin-top: 30px;
      .home_daily_mainnews {
        width: 738px;
        height: 418px;
        .mainnews_infor {
          position: relative;
          .mainnews_content {
            position: absolute;
            bottom: 0;
            z-index: 1;
            .mainnews_date {
              display: flex;
              text-align: center;
              align-items: center;
              color: #fff;
              margin-left: 10px;
              p {
                margin-bottom: 0;
                margin-left: 10px;
                color: #fff;
                font-family: "Oswald", sans-serif;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
              }
              img {
                color: white;
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(63deg)
                  brightness(100) contrast(101%);
              }
            }
            .mainnews_title {
              color: #fff;
              font-family: "Oswald", sans-serif;

              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 32px;
              margin-left: 10px;
              &:hover {
                color: rgba(198, 205, 94, 0.998);
                font-weight: 500;
              }
            }
          }
          .img_mainnews {
            background-size: cover;
            background-repeat: no-repeat;
            width: 738px;
            height: 418px;
            object-fit: cover;
          }
          .after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 110%
            );
          }
        }
      }
      .home_daily_subnews {
        flex: 1;
        margin-left: 10px;
        .subnews_wrapper {
          cursor: pointer;
          height: 130px;
          border-bottom: 2px solid var(--primary-color);
          display: flex;
          justify-content: space-around;
          text-align: center;
          margin-top: 10px;
          &:last-child {
            border-bottom: 0;
          }
          .subnews_title {
            display: -webkit-box;
            max-width: 400px;
            height: 109.2px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.625;
            text-align: left;
            .subnews_date {
              display: flex;
              text-align: center;
              align-items: center;
              color: #7f8183;
              svg {
                font-size: 15px;
              }
              p {
                margin-bottom: 0;
                margin-left: 10px;
                font-family: "Oswald", sans-serif;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                display: flex;
                align-items: center;
                color: #7f8183;
              }
            }
            .subnews_header {
              width: 304px;
              color: #766f3c;
              padding-top: 10px;
              font-family: "Oswald", sans-serif;
              font-size: 16px;
              font-weight: 700;
              line-height: 19px;
              &:hover {
                color: rgba(198, 205, 94, 0.998);
                p {
                  font-weight: 700;
                }
              }
            }
          }
          .subnews_img {
            width: 197px;
            height: 123.25px;
            img {
              background-size: cover;
              height: 120px;
              object-fit: cover;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .home_partners {
    // height: 400px;
    padding: var(--deafault-layout-padding);
    .home_partners_header {
      display: flex;
      text-align: center;
      justify-content: center;
      font-weight: 400;
      font-size: 40px;
      line-height: 63px;
      color: #606637;
      text-transform: uppercase;
      font-family: "Saysettha";
      font-family: "Oswald", sans-serif;
    }
    .home_partners_content {
      margin-top: 30px;
      .logo_partners {
        height: 380px;
        margin-bottom: 70px;
        margin-right: 0;
        margin-left: 0;
        .logo_partners_img {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .home_contactUs {
    padding: var(--deafault-layout-padding);
    position: relative;
    padding-bottom: 40px;
    .home_contact_img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      z-index: -1;
    }
    .header_contact {
      font-family: "Oswald", sans-serif;
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #606637;
      font-size: 40px;
      justify-content: center;
    }
    .content_contact {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 115px;
      justify-content: space-between;
      align-items: center;

      .box {
        flex: 1;
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
      .box-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text-box-right {
          font-family: "Oswald", sans-serif;
          font-weight: 400;
          font-size: 32px;
          span {
            color: #606637;
          }
        }
      }
      .box-right-img {
        border-radius: 50%;
        height: 280px;
        width: 280px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .header-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .text_content_box {
          font-weight: 400;
        }
      }
      .content_box {
        flex: 1;
        display: flex;
        gap: 10px;
        font-family: "Oswald", sans-serif;
        align-items: center;
        text-transform: uppercase;
        .header_content_box {
          font-size: 14px;
          font-family: "Oswald", sans-serif;
          font-weight: 400;
          text-transform: uppercase;
        }
        .img_box_content {
          height: 60px;
          width: auto;
          min-width: 74px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
@import "HomeResponsive";
