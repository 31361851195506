.admin_layout_wrapper {
  display: flex;

  .admin_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 243px;
    // height: 1007px;
    background: #464932;
    .menu_header {
      .logo {
        margin: 0 25px;
        margin-top: 20px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 193px;
        height: 66px;
        background-color: #fff;
        cursor: pointer;
        p {
          font-weight: 700;
          font-size: 30px;
          line-height: 41px;
          text-transform: uppercase;
          color: #464932;
          font-family: "Oswald", sans-serif;
          margin-bottom: 0;
          a {
            font-family: "Oswald", sans-serif;
            color: #000;
          }
        }
      }
      .language_option {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 20px;
        .language_E,
        .language_L {
          cursor: pointer;
          display: flex;
          p {
            color: #f4f5f6;
            margin-top: 0;
            font-family: "Oswald", sans-serif;
            margin-bottom: 0;
          }
        }
        .divide_lan {
          display: flex;
          align-items: center;
          width: 2px;
          background: #b1a96e;
          margin-left: 10px;
          margin-right: 10px;
          height: 16px;
        }
      }
      .content_selection {
        cursor: pointer;
        margin: 0 16px;
        display: flex;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
        width: 211px;
        height: 60px;
        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 38px;
          color: #f4f5f6;
          margin-left: 24px;
          font-family: "Oswald", sans-serif;
          margin-bottom: 0;
        }
      }
      // check select
      .content_selected {
        cursor: pointer;
        display: flex;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
        width: 211px;
        height: 60px;
        background-color: #f4f5f6;
        border-radius: 10px;
        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 38px;
          color: #464932;
          margin-left: 24px;
          font-family: "Oswald", sans-serif;
          margin-bottom: 0;
        }
      }
    }
    .menu_bottom {
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 243px;
      height: 60px;
      background: #f4f5f6;
      &:hover {
        background-color: #b1a96e;
      }
      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #464932;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        margin-bottom: 0;
      }
      .icon {
        margin-left: 10px;
      }
    }
  }
  .admin_content {
    margin-left: 243px;
    flex: 1;
    .admin_content_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      height: 100px;
      background: #f4f5f6;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      .content_header_title {
        margin: 0 20px;
        p {
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          text-transform: uppercase;
          color: #232708;
          font-family: "Oswald", sans-serif;
          margin-bottom: 0;
        }
      }
      .content_header_user {
        cursor: pointer;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 38px;
          margin-bottom: 0;
          font-family: "Oswald", sans-serif;
          margin-right: 20px;
        }
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        .changePassword {
          display: none;
          position: absolute;
          top: 45px;
          &.changePass {
            display: block;
            padding: 5px 20px;
            border-radius: 5px;
            color: #514c2a;
            button {
              padding: 5px 20px;
              border-radius: 5px;
              background-color: #514c2a;
              border: 1px solid #514c2a;
            }
          }
        }
      }
    }
  }
}
.ant-modal-wrap.changePassWord {
  .ant-modal-body {
    form {
      position: relative;
      label {
        width: 200px !important;
        font-family: "Saysettha" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 25px !important;
      }
      .wrongPass {
        color: red;
        position: absolute;
        display: none;
        &.noticeWrongPass {
          font-family: "Oswald", sans-serif;
          display: block;
        }
      }
      .label_newpass {
        font-family: "Oswald", sans-serif;
        margin-top: 30px;
      }
      input {
        width: 470px;
        height: 43px;
        background-color: #d9d9d9;
        color: #1c1f20;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        padding-left: 10px;
        border: 0px;
        font-family: "Oswald", sans-serif;
        border-radius: 5px;
      }
    }
  }
}
