@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}
.product_table {
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
      height: 32px;
      padding: 7px;
      background: #606637;
      color: #f4f5f6;
      font-family: "Oswald", sans-serif;
    }
  }
  .ant-table-tbody {
    vertical-align: top;
    .ant-table-cell {
      &:first-child {
        font-family: "Saysettha";
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        font-family: "Oswald", sans-serif;
      }
      &:nth-child(2) {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        font-family: "Oswald", sans-serif;
      }
      &:nth-child(5) {
        color: #1c1f20;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        height: 232px;
        line-height: 25px;
        font-family: "Oswald", sans-serif;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "Oswald", sans-serif;
        }
      }
      .img_gallery {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 170px;
        img {
          height: 110px;
          width: 86px;
        }
      }
      &:nth-child(4) {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        font-family: "Oswald", sans-serif;
      }
    }
  }
  .ant-table-cell {
    border-bottom: 1px solid #000;
    font-family: "Oswald", sans-serif;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    p {
      font-family: "Oswald", sans-serif;
      svg {
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        margin-right: 5px;
      }
    }
  }
  .operation_btn {
    display: flex;
    flex-direction: column;
    .ant-typography {
      color: #1c1f20;
      font-family: "Oswald", sans-serif;
      &:nth-child(2) {
        color: #1890ff;
      }
      &:last-child {
        color: #e70000;
      }
    }
  }
  li.ant-pagination-item {
    border: 0;
    background-color: #fff;
    color: #606637;
    font-family: "Oswald", sans-serif;
  }
  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    color: #fff;
    font-family: "Oswald", sans-serif;
  }
  button.ant-pagination-item-link {
    border: 0;
    font-family: "Oswald", sans-serif;
  }
  .ant-pagination-options {
    display: none;
  }
  .modal_wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    margin: 20px 0;
    button.ant-btn.ant-btn-primary {
      display: flex;
      align-items: center;
      text-align: center;
      // width: 214px;
      height: 44px;
      background: #464932;
      border-radius: 10px;
      border: 0px;
      font-family: "Oswald", sans-serif;
      svg {
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-right: 20px;
      }
      span {
        font-family: "Saysettha";
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        font-family: "Oswald", sans-serif;
      }
    }
  }
}

.ant-modal-wrap.modal_products {
  // overflow: hidden;
  .ant-modal {
    // margin: 0px 345px !important;
    width: 52% !important;
    height: 60% !important;
    // margin: 0px 200px;

    .ant-modal-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .ant-modal-header {
        .ant-modal-title {
          margin-left: 83px;
          font-family: "Saysettha";
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          text-transform: uppercase;
          color: #1c1f20;
          font-family: "Oswald", sans-serif;
        }
      }
      .ant-modal-body {
        // width: 945px;
        width: 100%;
        height: 100%;
        // margin-left: 70px;
        padding-top: 10px;
        overflow: auto;
        overflow-x: hidden;
        height: 550px;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 0 !important;
          display: none;
        }
        .view_page {
          display: flex;
          .content_img {
            width: 317px;
            height: 330px;
            .img_view {
              height: 300px;
              width: 315px;
              img {
                height: 300px;
                width: 315px;
              }
            }

            .img_selection {
              display: flex;

              position: relative;
              .list-image {
                display: flex;
                width: 631px;
                justify-content: space-around;
                div {
                  img {
                    object-fit: cover;
                  }
                  &:first-child {
                    img {
                      margin-left: 0;
                    }
                  }
                  &:last-child {
                    img {
                      margin-right: 0 !important;
                    }
                  }

                  .img_select {
                    cursor: pointer;
                    width: 80px;
                    height: 110px;
                    margin-top: 10px;
                    &.img_selected {
                      border: 2px solid red;
                    }
                  }
                }
              }
              .icon_left {
                position: absolute;
                left: 10px;
                top: 40%;
                padding: 10px 10px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-family: "Oswald", sans-serif;
              }
              .icon_right {
                position: absolute;
                right: 10px;
                top: 40%;
                padding: 10px 10px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-family: "Oswald", sans-serif;
              }
            }
            &.img_selected {
              border: 2px solid red;
            }
          }
          .content_wrap {
            flex: 1;
            width: 200px;
            .content_view {
              display: block;
              white-space: pre-wrap;
            }
          }
        }

        h3 {
          margin-bottom: 19px;
          font-family: "Saysettha";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #1c1f20;
          font-family: "Oswald", sans-serif;
        }
        form {
          .label_tree_product {
            display: flex;
            // text-align: center;
            align-items: center;
            label {
              font-style: normal !important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 25px !important;
              width: 160px !important;
              height: 25px;
              margin-left: 20px;
              color: #232708;
              font-family: "Oswald", sans-serif !important;
            }
          }

          div {
            // margin-left: 20px;
            // display: flex;
            // flex-direction: column;
            &:nth-child(5) {
              margin-left: 0;
              label.title_modal_product {
                font-family: "Saysettha";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: #1c1f20;
                margin-bottom: 20px;
                font-family: "Oswald", sans-serif;
              }
              label {
                font-family: "Saysettha";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                color: #1c1f20;
                width: 100%;
                margin: 5px 0;
                font-family: "Oswald", sans-serif;

                &:nth-child(1) {
                  font-family: "Saysettha";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 25px;
                  color: #1c1f20;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 32px;
                  font-family: "Oswald", sans-serif;
                }
              }
            }
            &:last-child {
              margin-left: 0;
              .upload_image {
                margin-left: 20px;
              }
            }

            input {
              width: 100%;
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              padding-left: 10px;
              border: 0px;
              font-family: "Oswald", sans-serif;
            }
          }
          .div_label {
            display: flex;
            label {
              width: 160px;
              height: 25px;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #1c1f20;
              display: flex;
              align-items: center;
              font-family: "Oswald", sans-serif;
              margin-left: 20px;
              :first-child {
                width: 100px;
              }
            }
          }
          .noticeOff {
            position: absolute;
            margin-left: 300px;
            text-transform: uppercase;
            color: red;
            font-family: "Oswald", sans-serif;
            display: none;
            &.noticeOn {
              display: block;
            }
          }
          .ant-upload-list-item-info {
            margin-left: 0 !important;
          }
          .ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture-card {
            margin-left: 0 !important;
          }
          .ant-upload-list-picture-card-container {
            margin-left: 0 !important;
          }
          .ant-select.ant-tree-select.ant-select-single.ant-select-show-arrow {
            // margin-left: 109px;
          }
          .ant-select.ant-select-single.ant-select-show-arrow {
            width: 100% !important;

            .ant-select-selector {
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 19px;
              padding: 8px 10px;
              font-family: "Oswald", sans-serif;
            }
            .ant-select-arrow {
              // right: -15px;
              color: #000;
            }
          }
        }
        .description_label {
          display: flex;
          margin-bottom: 16px;
          flex-direction: column;
          label {
            width: 180px;
            height: 25px;
            display: flex;
            align-items: center;
            margin-left: 20px;
            font-family: "Oswald", sans-serif;
          }
          textarea {
            height: 120px;
            width: 100%;
            background: #d9d9d9;
            border: 0px;
            font-family: "Oswald", sans-serif;
          }
        }
        .view_page {
          height: 720px;
          width: 900px;
          gap: 20px;
          p {
            margin: 0 20px;
            margin-top: 15px;
            display: block;
            height: 500px;
            font-family: "Saysettha";
            font-style: normal;
            font-weight: 400;
            font-size: 16.0557px;
            line-height: 25px;
            font-family: "Oswald", sans-serif;
            color: #1c1f20;
            &::-webkit-scrollbar {
              width: 0 !important;
              display: none;
            }
          }
        }
      }
      .ant-modal-footer {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        button.ant-btn.ant-btn-primary {
          width: 94px;
          height: 44px;
          left: 0px;
          top: 0px;
          background: #464932;
          border-radius: 10px;
          font-family: "Oswald", sans-serif;
          border: 0px;
        }
        button.ant-btn.ant-btn-default {
          width: 94px;
          height: 44px;
          border-radius: 10px;
          margin-left: 20px;
          border: 1px solid #464932;
          font-family: "Oswald", sans-serif;
        }
      }
    }
  }
}
span.ant-select-tree-switcher.ant-select-tree-switcher_open {
  display: none;
}
.ant-popover-message {
  display: flex;
  text-align: center;
  align-items: center;

  svg {
    color: red;
    font-size: 20px;
  }
  .ant-popover-message-title {
    color: red;
    font-size: 11px;
    font-family: "Oswald", sans-serif;
    padding-left: 10px;
  }
}
.ant-popover-buttons {
  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background: red;
    border: 0px;
    border-radius: 5px;
    :hover {
      color: rgb(168, 152, 179);
    }
  }
  button.ant-btn.ant-btn-default.ant-btn-sm {
    background: rgb(66, 66, 148);
    border: 0px;
    border-radius: 5px;
    font-family: "Oswald", sans-serif;
    color: white;
    :hover {
      background: rgb(50, 50, 149);
      color: rgb(168, 152, 179);
    }
  }
}

.quill {
  border: 1px solid black;
  &.quill-cls-custom {
    .ql-container {
      .ql-editor {
        border: 1px black;
        width: 100%;
        height: 250px !important;
        background-color: #ebe8e8 !important;
        font-family: "Saysettha";
        line-height: 32px;
        color: #fff;
        padding-left: 20px;
        font-family: "Oswald", sans-serif;
        img {
          max-width: 300px;
        }
      }
    }
  }
}
