li.ant-pagination-item {
    border: 0;
    border-radius: 0 !important ;
    background-color: #fff;
    color: #606637;
}

li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    color: #fff;
    border-radius: none ;
}

li.ant-pagination-prev .ant-pagination-item-link, li.ant-pagination-next .ant-pagination-item-link{
    border: none;
    border-radius: none;
}

span.anticon {
    color: black;
}
