@media (min-width: 740px) and (max-width: 950px) {
  .warpper_search {
    margin: 0;
    .result_search {
      margin: 0;
      justify-content: space-around;
      .product_search {
        width: initial;
        margin-right: 30px;
        a {
          .items_search {
            .img_search {
              width: 100%;
            }
            p {
              width: 280px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .warpper_search {
    margin: 0;
    .result_search {
      justify-content: center;
      margin: 0;
      .product_search {
        width: initial;
        margin: 0;
        margin-bottom: 100px;
        a {
          .items_search {
            .img_search {
              width: 100%;
            }
            p {
              width: 280px;
            }
          }
        }
      }
    }
  }
}
