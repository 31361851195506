.wrapper_breadcrumb {
  height: 30px;
  background-color: #cad09d;

  .breadcrumb_route {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0 calc(50% - 640px);
    height: 30px;
    p {
      margin-bottom: 0;
    }
    .arrow-right {
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;

      border-left: 15px solid #606637;
    }

    li {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      position: relative;
      min-width: 100px;
      height: 30px;
      padding: 5px 10px;
      padding-left: 20px;
      background: linear-gradient(
        270deg,
        #606637 -0.43%,
        #b2b886 74.72%,
        #cad09d 100%
      );
      &:last-child {
        max-width: 300px;
        display: flex;
        align-items: center;
      }
      span:first-child {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        display: none;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Oswald", sans-serif;
      }
      span {
        color: #fff;
        padding: 5px;
        cursor: default;
        &:last-child {
          display: none;
          // display: block;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapper_breadcrumb {
    display: none;
  }
}
