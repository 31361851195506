@font-face {
  src: url("../../../assets/font/laoscitechFont.otf");
  font-family: "Terminator Real NFI";
}

.wrapper_product_list {
  padding: var(--deafault-layout-padding);
  // height: 510px;
  margin-bottom: 60px;

  .header_prodList {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    h2 {
      color: var(--primary-color);

      font-weight: 700;
      font-size: 30px;
      line-height: 54px;
      text-transform: uppercase;
      margin-bottom: 0;
      cursor: default;
    }
    .custom_font_family {
      font-family: "Oswald", sans-serif;
      font-weight: 400;
    }
  }
  .body_prodList {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .list_product {
      // width: 350px;
      width: 100%;

      .header_product {
        display: flex;
        margin-bottom: 10px;

        h3 {
          cursor: pointer;
          border: 2px solid var(--primary-color);
          margin-bottom: 5px;
          padding: 10px 5px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: var(--primary-color);
          font-family: "Oswald", sans-serif;
          padding-left: 20px;
        }
        .product_name {
          padding-right: 15px;
          gap: 5px;
          border-right: 1px solid #d1cccc;
          .item_parent {
            padding: 5px;
            // min-width: 400px;
            // background-color: var(--primary-color);
            margin-left: 20px;
            display: flex;
            padding-left: 30px;
            // width: 400px;
            color: var(--primary-color);
            font-family: "Oswald", sans-serif;
            margin-bottom: 0;
            a {
              font-family: "Oswald", sans-serif;
              color: #fff;
            }
            &.activeItem {
              color: #fff;
              background-color: var(--primary-color);
              border-bottom: 0px solid #494d1e;
            }

            .titleCategory {
              border: 0;
              margin-bottom: 0;
              color: #fff;
              display: -webkit-box;
              // -webkit-line-clamp: 1;
              -webkit-box-orient: horizontal;
              overflow: hidden;
              text-overflow: ellipsis;
              // width: 350px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              font-family: "Oswald", sans-serif;
              text-overflow: ellipsis;
            }
            &.classParent {
              // background-color: #494d1e;
              margin-left: 0;
              // min-width: 420px;
              // width: 420px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border-bottom: 1px solid var(--primary-color);
              font-family: "Oswald", sans-serif;

              &.activeItem {
                color: #fff;
                background-color: var(--primary-color);
                border-bottom: 1px solid var(--primary-color);
                border: 0;
              }
              a {
                font-family: "Oswald", sans-serif;
                color: #fff;
              }
              p {
                // width: 350px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: horizontal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                font-family: "Oswald", sans-serif;
                text-overflow: ellipsis;
              }
            }
          }
          // width: 300px;
          display: flex;
          flex-direction: column;
          .sub_title {
            width: 100%;
            cursor: pointer;
            border: 2px solid rgba(202, 208, 157, 0.7);
            margin-bottom: 0;
            margin-top: 7px;
            padding: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            padding-right: 60px;
            background-color: rgba(202, 208, 157, 0.7);
            color: black;
            text-transform: uppercase;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
          }
          &.merch_click {
            color: var(--primary-color);
            background-color: #fff;
          }
        }
        .product_detail__ {
          flex: 1;
          height: 100%;
          width: 100%;
          margin-left: 15px;
          &.parent {
            display: flex;
            flex-wrap: wrap;
          }
          .product {
            flex: 1;
            flex-grow: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            width: 100%;
            margin-bottom: 20px;
            margin-left: 30px;
            .product_img {
              width: 260px;
              height: 100%;
              margin-left: 20px;
              cursor: pointer;
              div {
                width: 260px;
                height: 200px;
                display: flex;
                justify-content: center;
                img {
                  object-fit: cover;
                  width: 100%;
                }
              }
              &:hover {
                // border: 1px solid;
                box-shadow: var(
                  --ds-shadow-overlay,
                  0 4px 8px -2px rgba(9, 30, 66, 0.25),
                  0 0 1px rgba(9, 30, 66, 0.31)
                );
              }
            }
            p {
              margin-bottom: 0;
              font-weight: 600;
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;
              width: 260px;
              height: 30px;
              background: rgb(68 75 9 / 90%);
              padding: 0 !important;
              text-transform: capitalize;
              display: none;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              font-family: "Oswald", sans-serif;
              text-overflow: ellipsis;
              color: #fff;
            }
          }
        }
      }
    }

    .product {
      flex: 1;
      flex-grow: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      width: 100%;
      margin-bottom: 20px;
      .product_img {
        width: 260px;
        height: 100%;
        margin-left: 20px;
        cursor: pointer;
        div {
          width: 260px;
          height: 200px;
          display: flex;
          justify-content: center;
          img {
            object-fit: cover;
            width: 100%;
          }
        }
        &:hover {
          // border: 1px solid;
          box-shadow: 5px 5px 4px #888888;
        }
      }
      p {
        margin-bottom: 0;
        font-weight: 600;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 260px;
        height: 30px;
        background: rgba(202, 208, 157, 0.7);
        font-family: "Oswald", sans-serif;
        padding: 0 !important;
      }
    }
  }
}

@import "ProductList_Responsive";
