@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Medium.ttf");

  font-family: "Saysettha_medium";
}
@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Bold.ttf");

  font-family: "Saysettha_bold";
}

.wrapper_prodetail {
  padding: var(--deafault-layout-padding);
  margin-bottom: 60px;
  p {
    margin-bottom: 0;
  }
  .header_product_detail {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 40px;
    font-weight: 700;
    font-size: 40px;
    line-height: 63px;
    color: #464932;
    h2 {
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
    }
  }
  .content_product_detail {
    display: flex;
    margin-top: 21px;
    gap: 20px;

    .content_img {
      flex: 0 0 50%;
      height: 700px;
      width: 631px;

      .img_view {
        width: 631px;
        height: 530px;
        margin-bottom: 30px;
        text-align: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .img_selection {
        display: flex;
        justify-content: center;
        position: relative;

        .list-image {
          display: flex;
          gap: 10px;
          justify-content: space-evenly;
          width: 100%;
          div {
            // flex: 0 0 80px;

            // width: 152px;
            // height: 182px;
            &:first-child {
              img {
                margin-left: 0;
              }
            }
            &:last-child {
              img {
                margin-right: 0 !important;
              }
            }

            .img_select {
              cursor: pointer;

              height: 142px;
              margin: 20px 10px;
              width: 100%;
              object-fit: cover;

              &.img_selected {
                border: 2px solid red;
              }
            }
          }
        }
        .icon_left {
          position: absolute;
          left: 10px;
          top: 40%;
          padding: 10px 10px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
        }
        .icon_right {
          position: absolute;
          right: 10px;
          top: 40%;
          padding: 10px 10px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
        }
      }
      &.img_selected {
        border: 2px solid red;
      }
    }
    .content_detail {
      flex: 1;
      margin-left: 20px;
      pre {
        display: block;
        white-space: pre-wrap;
        font-weight: 400;
        font-size: 16px;
        font-family: "Oswald", sans-serif;
        overflow-y: hidden;
      }
      .content_text {
        margin-top: 30px;
        font-weight: 400;
        font-size: 16px;
        font-family: "Oswald", sans-serif;

        line-height: 25px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        font-family: "Oswald", sans-serif;

        line-height: 25px;
      }
      span {
        font-weight: 600;
        line-height: 25px;
        font-size: 16px;
        font-family: "Oswald", sans-serif;
      }
    }
  }
}
@import "ProductDetail_Responisve";
