.wrapper_replace {
  margin-bottom: 50px;
  .container_replace {
    .sub_replace {
      .ql-align-center {
        display: flex;
        justify-content: center;
      }
    }
  }
}

// Tablet
@media screen and (max-width: 768px) {
  .grid_image{
      display: flex;
      flex-direction: column;
  }
  .image_item:first-child,
  .image_item:nth-child(2),
  .image_item:nth-child(3),
  .image_item:nth-child(4),
  .image_item:nth-child(5),
  .image_item:last-child{
      height: 300px;
  }
  .image_item-commited{
      height: 300px;
      width: 100%;
  }
}

.ql-align-center{
  text-align: center;
}

.ql-align-right{
  // width: 100%;
  text-align: right;
}

.ql-align-justify{
  text-align: justify;
}

p img{
  max-width: 400px;
}
