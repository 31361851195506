@font-face {
  src: url("../../assets/font/font_family/Saysettha-Medium.ttf");

  font-family: "Saysettha";
}

.wrapper_footer {
  // height: 373px;
  background-image: url(../../assets/images/footer_bg.png);
  background-color: #464932;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer_top {
  margin: var(--deafault-layout-margin);
  padding: 20px 30px;
  padding-bottom: 0;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  // text-transform: uppercase;
  .footer_infor {
    display: flex;
    padding: 0 120px;
    justify-content: space-between;

    .footer_title {
      display: flex;
      flex-direction: column;
      flex: 1;
      // justify-content: space-around;

      h2 {
        cursor: default;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 38px;
        text-transform: none;

        font-family: "Oswald", sans-serif;
        a {
          color: #fff;
        }
      }
      h5 {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-transform: none;
        font-family: "Oswald", sans-serif;

        a {
          color: #fff;
          font-weight: 300;
          font-family: "Oswald", sans-serif;
          &:hover {
            color: #adb95d;
          }
        }
      }
      .footer__contact {
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 80px;
        // margin-bottom: 30px;
        // width: 270px;
        p {
          cursor: default;
          margin-bottom: 0;
          padding: 10px 0;
          color: #fff;
          font-family: "Oswald", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: #f4f5f6;
        }
        .footer_icon {
          margin-right: 39px;
          width: 70px;
          font-size: 52px;
          color: #fff;
          img {
            width: 55px;
            height: 55px;
            max-width: initial;
            margin-right: 15px;
          }
          svg {
            transform: rotate(-30deg);
          }
        }
        .footer_detail {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
        }
        .icon_rotate {
          svg {
            transform: rotate(0deg);
          }
        }
      }
      .email {
        padding-top: 25px;
      }
    }
  }
  .footer_contact {
    display: flex;
    flex-direction: column;
    .footer__contact {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 30px;

      p {
        cursor: default;
        margin-bottom: 0;
        padding: 10px 0;
        color: #fff;
        font-family: "Oswald", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #f4f5f6;
      }
      .footer_icon {
        width: 70px;
        font-size: 52px;
        color: #fff;
        svg {
          transform: rotate(-30deg);
        }
      }
      .footer_detail {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        p {
          text-transform: initial;
        }
      }
      .icon_rotate {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }
  .footer_contactmb {
    display: none;
    flex-direction: column;
    .footer__contact {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 30px;
      p {
        cursor: default;
        margin-bottom: 0;
        padding: 10px 0;
        color: #fff;
        font-family: "Oswald", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #f4f5f6;
      }
      .footer_icon {
        width: 70px;
        font-size: 52px;
        color: #fff;
        img {
          width: 55px;
          height: 55px;
          max-width: initial;
          margin-right: 15px;
        }
        svg {
          transform: rotate(-30deg);
        }
      }
      .footer_detail {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
      }
      .icon_rotate {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.footer_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #464932;
  height: 55px;
  // color: #fff;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 16px;
  //         font-family: "Oswald", sans-serif;

  p {
    margin-bottom: 0;
    font-family: "Oswald", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #fff;
  }
}
@import "FooterResponsive";
