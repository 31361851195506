@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper_product_list {
    height: 100%;
    .body_prodList {
      .product {
        flex-direction: column;
        .product_img {
          margin-bottom: 60px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper_product_list {
    height: 100%;
    .body_prodList {
      .product {
        width: initial;
        flex-direction: column;
        margin-top: 30px;
        .product_img {
          width: initial;
          margin-bottom: 60px;
          margin-left: 0;
          &:first-child {
            margin-left: 0;
          }
        }
        p {
          width: initial;
        }
      }
    }
  }
}
