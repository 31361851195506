.static_content_ant {
  .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      border: 0;
      background: transparent;
      margin-left: 10px;
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      font-family: "Oswald", sans-serif;
      line-height: 25px;
    }
  }
  .home_banner_table {
    label {
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #1c1f20;
      font-family: "Oswald", sans-serif;
      margin: 5px 0;
    }
  }
  .table_home {
    thead.ant-table-thead {
      height: 40px;
      background: #606637;
      border-bottom: 1px solid #f4f5f6;
      font-family: "Oswald", sans-serif;
      text-align: center;
      th.ant-table-cell {
        text-align: center;
        height: 40px;
        background: #606637;
        border-bottom: 1px solid #f4f5f6;
        font-family: "Oswald", sans-serif;
        color: #f4f5f6;
      }
    }
    tbody.ant-table-tbody {
      tr {
        font-family: "Oswald", sans-serif;
        height: 75px;
        td.ant-table-cell.ant-table-cell-row-hover {
          &:hover {
            font-family: "Oswald", sans-serif;
            background: #88814f !important;
            cursor: pointer;
            .operation_btn {
              a {
                opacity: 1;
              }
            }
          }
        }
        td.ant-table-cell-row-hover {
          font-family: "Oswald", sans-serif;
          background: #88814f !important;
          &:hover {
            background: #88814f !important;
            cursor: pointer;
            .operation_btn {
              a {
                font-family: "Oswald", sans-serif;
                opacity: 1;
              }
            }
          }
        }
      }

      td.ant-table-cell {
        height: 30px;
        padding: 0;
        font-family: "Oswald", sans-serif;

        &:first-child {
          p {
            font-family: "Oswald", sans-serif;
            margin-left: 20px;
          }
        }
        &:nth-child(3) {
          .operation_btn {
            display: flex;
            justify-content: space-evenly;
            font-family: "Oswald", sans-serif;
            position: relative;

            a {
              font-family: "Oswald", sans-serif;
              position: absolute;
              &:first-child {
                top: -23px;
                right: 20px;
                font-size: 16px;
                font-family: "Oswald", sans-serif;
                opacity: 0;
                div {
                  img {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
              &:last-child {
                top: -20px;
                left: 20px;
                color: red;
                font-size: 16px;
                font-family: "Oswald", sans-serif;
                opacity: 0;
              }
            }
          }
          .operation_btn {
            display: flex;
            justify-content: space-evenly;
            font-family: "Oswald", sans-serif;
            position: relative;

            a {
              font-family: "Oswald", sans-serif;
              position: absolute;
              &:first-child {
                top: -23px;
                right: 20px;
                font-size: 16px;
                opacity: 0;
                div {
                  img {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
              &:last-child {
                top: -20px;
                left: 20px;
                color: red;
                font-size: 16px;
                opacity: 0;
              }
            }
          }
        }
        &:nth-child(3) {
          text-align: center;
          font-family: "Oswald", sans-serif;
        }

        &:hover {
          background: #88814f !important;
          cursor: pointer;
          .operation_btn {
            a {
              opacity: 1;
              font-family: "Oswald", sans-serif;
            }
          }
        }
      }
      td.ant-table-cell {
        background: #b1a96e;
        font-family: "Oswald", sans-serif;
        div {
          p {
            // margin-left: 20px;
            margin-bottom: 0 !important;
          }
        }
      }
      .ant-empty-description {
        font-family: "Oswald", sans-serif;
        color: #fff;
      }
      tr.ant-table-row.editable-row {
        width: 30px;
        padding: 0;
        font-family: "Oswald", sans-serif;
        background: #b1a96e;
        &:hover {
          font-family: "Oswald", sans-serif;
          background: #88814f !important;
        }
      }
    }
  }
  .modal_wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    font-family: "Oswald", sans-serif;
    background: #b1a96e;
    padding: 15px;
    button.ant-btn.ant-btn-primary {
      display: flex;
      font-family: "Oswald", sans-serif;
      align-items: center;
      text-align: center;

      height: 44px;
      background: #f4f5f6;
      border-radius: 10px;
      border: 0px;
      svg {
        padding: 2px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 14px;
        color: black;
      }
      span {
        font-family: "Saysettha";
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        font-family: "Oswald", sans-serif;
        color: black;
      }
      // &:hover {
      //   background: #464932;
      //   svg {
      //     border: white 1px solid;
      //     color: white;
      //   }
      //   span {
      //     color: white;
      //   }
      // }
    }
  }
  .noticeOff {
    position: absolute;
    margin-left: 300px;
    text-transform: uppercase;
    color: red;
    margin-top: 55px;
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeOn {
      display: block;
    }
  }
  .noticeSumbitSuc {
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    font-family: "Oswald", sans-serif;
    color: rgb(60, 60, 186);
    display: none;
    &.noticeSucOn {
      display: block;
    }
  }
  .noticeSumbitFail {
    text-transform: uppercase;
    position: absolute;
    font-family: "Oswald", sans-serif;
    right: 30px;
    color: rgb(209, 35, 35);
    display: none;
    &.noticeFailOn {
      display: block;
    }
  }
  .submit_btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    font-family: "Oswald", sans-serif;
    padding-bottom: 30px;
    margin-top: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 15px;
      font-family: "Saysettha";
      font-weight: 700;
      height: 50px;
      width: 90px;
      align-items: center;
      border: 0;
      background: #b1a96e;
      color: #fff;
      font-family: "Oswald", sans-serif;
      border-radius: 10px;
    }
  }
}

.modal_StacticContent {
  right: 30% !important;
  font-family: "Oswald", sans-serif;
  overflow: initial !important;

  table,
  th,
  td {
    font-family: "Oswald", sans-serif;
    border: 2px solid #343914;
  }
  td {
    color: #606637;
    font-family: "Oswald", sans-serif;
    height: 160px;
  }
  .ant-modal-content {
    width: 1156px;
    font-family: "Oswald", sans-serif;
    height: 362px;
  }
  .ant-modal-body {
    width: 1156px;
    font-family: "Oswald", sans-serif;
    height: 300px;

    table {
      width: 100%;
      margin-top: 40px;
      font-family: "Oswald", sans-serif;
      text-align: center;
      .table_header_modal {
        height: 40px;
        width: 900px;
        background: #606637;
        color: #fff;
        font-family: "Oswald", sans-serif;
        border: 2px solid #343914;
        &:first-child {
          width: 70px;
        }
        &:last-child {
          width: 50px;
        }
      }
      .table_content {
        font-family: "Oswald", sans-serif;
        .ant-upload-list.ant-upload-list-picture {
          width: 96px;
          font-family: "Oswald", sans-serif;
        }
        vertical-align: top;
        textarea {
          width: 100%;
          border: 0;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          font-family: "Oswald", sans-serif;
          color: #606637;
          &:focus {
            outline: 0;
          }
        }
        input {
          padding-left: 5px;
          width: 100%;
          height: 40px;
          border: 0;
          font-weight: 400;
          font-size: 20px;
          font-family: "Oswald", sans-serif;
          line-height: 32px;
          color: #606637;
          &:focus {
            outline: 0;
          }
        }
        input[type="file"] {
          border: 0px;
          background: transparent;
          // visibility: hidden;
        }
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    button.ant-btn.ant-btn-primary {
      width: 94px;
      height: 44px;
      left: 0px;
      top: 0px;
      font-family: "Oswald", sans-serif;
      background: #464932;
      border-radius: 10px;
      border: 0px;
    }
    button.ant-btn.ant-btn-default {
      width: 94px;
      height: 44px;
      border-radius: 10px;
      font-family: "Oswald", sans-serif;
      margin-left: 20px;
      border: 1px solid #464932;
    }
  }
}
span.anticon.anticon-eye {
  display: none;
  font-family: "Oswald", sans-serif;
}

.aboutUs {
  .aboutUsTitle {
    .noticeOffAboutUs_E {
      background-color: transparent !important;
      position: absolute;
      margin-left: 300px;
      text-transform: uppercase;
      color: red;
      display: none;
      font-family: "Oswald", sans-serif;
      &.noticeOnAboutUs {
        display: block;
      }
    }

    .noticeOffAboutUs_L {
      background-color: transparent !important;
      position: absolute;
      margin-left: 300px;
      text-transform: uppercase;
      color: red;
      font-family: "Oswald", sans-serif;
      display: none;
      &.noticeOnAboutUs {
        display: block;
      }
    }
  }
  .noticeOffAboutUs_E {
    background-color: transparent !important;
    position: absolute;
    margin-left: 300px;
    text-transform: uppercase;
    color: red;
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeOnAboutUs_E {
      display: block;
    }
  }
  .noticeSumbitSucAboutUs_E {
    background-color: transparent !important;
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    color: rgb(60, 60, 186);
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeSucOnAboutUs_E {
      display: block;
    }
  }
  .noticeSumbitFailAboutUs_E {
    background-color: transparent !important;
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    color: rgb(209, 35, 35);
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeFailOnAboutUs_E {
      display: block;
    }
  }

  .noticeOffAboutUs_L {
    background-color: transparent !important;
    position: absolute;
    margin-left: 300px;
    text-transform: uppercase;
    color: red;
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeOnAboutUs_L {
      display: block;
    }
  }
  .noticeSumbitSucAboutUs_L {
    background-color: transparent !important;
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    color: rgb(60, 60, 186);
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeSucOnAboutUs_L {
      display: block;
    }
  }
  .noticeSumbitFailAboutUs_L {
    background-color: transparent !important;
    text-transform: uppercase;
    position: absolute;
    right: 30px;
    color: rgb(209, 35, 35);
    font-family: "Oswald", sans-serif;
    display: none;
    &.noticeFailOnAboutUs_L {
      display: block;
    }
  }
}

.quill {
  border: 1px solid black;
  .ql-container {
    .ql-editor {
      border: 1px black;
      width: 100%;
      height: 93px;
      background-color: #ebe8e8 !important;
      font-family: "Saysettha";
      line-height: 32px;
      color: #fff;
      padding-left: 20px;
      font-family: "Oswald", sans-serif;
      img {
        max-width: 300px;
      }
    }
  }
}
.ql-editor.ql-blank::before {
  font-family: "Oswald", sans-serif;
  padding-left: 10px;
}
.aboutUsContent {
  font-family: "Oswald", sans-serif;
  .quill {
    .ql-container {
      .ql-editor {
        height: 500px !important;
        font-family: "Oswald", sans-serif;
        background-color: #ebe8e8;
      }
    }
  }
}
