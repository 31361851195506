@media (min-width: 740px) and (max-width: 1023px) {
  .wrapper_home {
    .home_tank {
      flex-direction: column;
      padding: 0 60px;
      height: 100%;
      .home_infor_detail {
        width: 100%;
      }
      .home_img {
        img {
          margin-top: 100px;
          position: initial;
        }
      }
    }
    .home_product {
      height: 100%;
      .homeproduct_selected {
        flex-direction: column;
        align-items: center;
        .homeproduct_detail {
          margin-bottom: 30px;
          .background_img {
            background-size: cover;
            .homeproduct_title {
              position: absolute;
              bottom: 0;
              height: 54px;
              &:hover {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .home_daily_new {
      height: 100%;
      .home_daily_content {
        flex-direction: column;
        .home_daily_mainnews {
          padding: 0 100px;
          width: 100%;
          .mainnews_infor {
            .img_mainnews {
              width: 100%;
            }
          }
        }
        .home_daily_subnews {
          margin-top: 50px;
          &:last-child {
            border-bottom: 2px solid var(--primary-color);
          }
        }
      }
    }
    .home_partners {
      height: 100%;
      .home_partners_content {
        .logo_partners {
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 740px) {
  .wrapper_home {
    .home_tank {
      flex-direction: column;
      padding: 0 10px;
      height: 100%;
      .home_infor_detail {
        width: 100%;
        .wrapper_table {
          .table_infor {
            padding: 0 5px;
          }
          .home_btn {
            margin-top: 22px;
          }
        }
      }
      .home_img {
        img {
          margin-top: 100px;
          position: initial;
        }
      }
    }
    .home_product {
      height: 100%;
      .homeproduct_selected {
        flex-direction: column;
        align-items: center;
        .homeproduct_detail {
          margin-bottom: 30px;
          .background_img {
            background-size: cover;
            height: 230px;
            width: 380px;
            .homeproduct_title {
              position: absolute;
              bottom: 0;
              height: 54px;
              background-color: transparent;
              p {
                height: initial;
                background-color: rgba(149, 155, 105, 0.7);
                margin-bottom: 0;
              }
              &:hover {
                height: 100%;
              }
            }
          }
        }
      }
    }
    .home_about {
      .homeabout_title_wrapp {
        .homeabout_title {
          font-size: 15px;
        }
      }
      .homeabout_btn_wrapp {
        .homeabout_btn {
          font-size: 12px;
          // width: 154px;
          height: 50px;
          padding: 5px;
        }
      }
    }
    .home_daily_new {
      height: 100%;
      .home_daily_content {
        flex-direction: column;
        .home_daily_mainnews {
          width: 100%;
          .mainnews_infor {
            .img_mainnews {
              width: 100%;
            }
          }
        }
        .home_daily_subnews {
          margin: 0 10px;
          margin-top: 50px;
          &:last-child {
            border-bottom: 2px solid var(--primary-color);
          }
          .subnews_wrapper {
            .subnews_title {
              .subnews_header {
                font-size: 15px;
                width: 200px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .home_partners {
      height: 100%;
      .home_partners_content {
        .logo_partners {
          height: 100%;
        }
      }
    }
    .home_contactUs {
      .content_contact {
        flex-direction: column;
      }
    }
  }
}
