.news_wrapper {
  margin: 20px;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    input {
      width: 350px;
      height: 44px;
      border: 1px solid #606637;
      border-radius: 5px 0px 0px 5px;
    }
    button {
      color: #fff;
      width: 56px;
      height: 44px;
      background: #606637;
      border: 2px solid #606637;
      border-radius: 0px 5px 5px 0px;
    }
  }
}

.thumbnail_wrapper{
  margin-bottom: 20px;
    .thumbnail{
      width: 200px;
      position: relative;
      .after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        .delete_image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: 0;
          svg{
            color: white;
          }
          
        }
      }
    }
    .thumbnail:hover{
      .after{
        display: block;
        background-color: rgba(0, 0, 0, 0.4);
        .delete_image{
          display: block;
        }
      }
    }
}