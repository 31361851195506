@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}

.wrapper_static_content {
  margin: 30px 20px;
  .home_page {
    h2 {
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 51px;
      font-family: "Oswald", sans-serif;
      color: #232708;
    }
    .home_banner_heading {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      label {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        font-family: "Oswald", sans-serif;
        color: #1c1f20;
        // margin-right: 30px;
        // margin-top: 10px;
        flex: 0 0 230px;
      }
      input {
        width: 470px;
        height: 36px;
        background: #d9d9d9;
        border: 0px;
        border-radius: 5px;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        font-family: "Oswald", sans-serif;
        color: #1c1f20;
        padding: 0 10px;
      }
    }
    .noticeOff {
      position: absolute;
      margin-left: 300px;
      text-transform: uppercase;
      color: red;
      font-family: "Oswald", sans-serif;
      display: none;
      &.noticeOn {
        display: block;
      }
    }
    .home_banner_image {
      label {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-family: "Oswald", sans-serif;
        font-size: 20px;
        line-height: 32px;
        color: #1c1f20;
        margin-top: 10px;
      }
    }
  }
  .about_page {
    margin-top: 30px;
    h2 {
      margin-top: 20px;
      font-family: "Saysettha";
      font-style: normal;
      font-weight: 400;
      font-family: "Oswald", sans-serif;
      font-size: 32px;
      line-height: 51px;
      color: #232708;
    }
    .about_page_title {
      margin-bottom: 50px;
    }
    .about_page_title,
    .about_page_content {
      position: relative;
      div {
        width: 100%;
        // background: #b1a96e;
        label {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          font-family: "Oswald", sans-serif;
          line-height: 33px;
          text-transform: uppercase;
          color: #1c1f20;
          padding: 10px;
        }
      }
      input {
        width: 100%;
        height: 93px;
        background: #ebe8e8;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        font-family: "Oswald", sans-serif;
        color: #1c1f20;
        padding-left: 20px;
      }
    }
  }
  .submit_btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 15px;
      font-family: "Saysettha";
      font-weight: 400;
      height: 50px;
      width: 90px;
      align-items: center;
      border: 0;
      font-family: "Oswald", sans-serif;
      background: #b1a96e;
      color: #fff;
      border-radius: 10px;
    }
  }
}
