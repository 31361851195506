.wrapper_product_list {
  padding: var(--deafault-layout-padding);
  // height: 510px;
  margin-bottom: 60px;
  .header_prodList {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    h2 {
      color: var(--primary-color);

      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      text-transform: uppercase;
      margin-bottom: 0;
      cursor: default;
    }
  }
  .body_prodList {
    display: flex;
    margin-top: 40px;
    .list_product {
      width: 350px;

      .header_product {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        h3 {
          cursor: pointer;
          border: 2px solid var(--primary-color);
          margin-bottom: 5px;
          padding: 10px 5px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: var(--primary-color);
          padding-left: 20px;
        }
        .product_name {
          margin-left: 20px;

          width: 300px;
          display: flex;
          flex-direction: column;
          p {
            cursor: pointer;
            border: 2px solid rgba(202, 208, 157, 0.7);
            margin-bottom: 0;
            margin-top: 7px;
            padding: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            padding-right: 60px;
            background-color: rgba(202, 208, 157, 0.7);
            color: var(--primary-color);
            text-transform: uppercase;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            // margin-bottom: 10px;
          }
          &.merch_click {
            color: var(--primary-color);
            background-color: #fff;
          }
        }
      }
    }

    .product {
      flex: 1;
      flex-grow: 0;
      display: flex;
      margin-bottom: 60px;
      width: calc(330px * 3);

      flex-wrap: wrap;

      .product_img {
        width: 305px;
        height: 230px;
        margin-left: 20px;
        margin-bottom: 20px;

        cursor: pointer;
        div {
          width: 305px;
          height: 200px;
          display: flex;
          justify-content: center;
          img {
            object-fit: cover;
            width: 100%;
          }
        }
        &:hover {
          // border: 1px solid;
          box-shadow: 5px 5px 4px #888888;
        }
        &:first-child {
          margin-left: 30px;
        }
      }
      p {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 305px;
        height: 30px;
        background: rgba(202, 208, 157, 0.7);
        padding: 0 !important;
      }
    }
  }
}

@import "ProductList_Responsive";
