/* -tablet-- */
@media (min-width: 740px) and (max-width: 950px) {
  .navbar_header {
    padding: 0 10px;
    font-size: 12px;

    &.search_icon {
      width: 55px;
      div {
        width: 35px;
        height: 35px;
        font-size: 15px;
      }
    }
    &.phone_icon {
      width: 42px;
      font-size: 20px;
    }
  }
}
@media (min-width: 950px) and (max-width: 1023px) {
  .navbar_header {
    padding: 0 12px;
    font-size: 13px;

    &.search_icon {
      width: 55px;
      div {
        width: 35px;
        height: 35px;
        font-size: 15px;
      }
    }
    &.phone_icon {
      width: 42px;
      font-size: 20px;
    }
  }
}
/* --Mobile-- */

@media (max-width: 740px) {
  .warpper_header {
    position: relative;
  }
  .header_top {
    margin: 0 10px;
  }
  .menu_mobile {
    display: block;
    // border-bottom: 2px solid white;
  }
  .header_bottom {
    display: none;
  }
  .header_bottom_mobile {
    display: flex;
    flex-direction: column;
    background-color: #565943;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-bottom: 2px solid white;
  }
  .navbar_headermb {
    width: initial;
    height: initial;
    &.search_iconmb {
      display: flex;
      justify-content: left;
      border: 0;
      color: white;
      div {
        width: 30px;
        height: 30px;
        font-size: 18px;
        color: white;
        border: 1px solid white;
      }
    }
  }
  .navbar_header {
    height: 100%;
    display: block;
    border-left: 0;
    border-bottom: 2px solid white;
    padding: 10px 5px;
    // padding-bottom: 0;
    // height: 40px;
    // padding: 5px 23px;
    position: relative;
    justify-content: flex-start;
    padding-left: 10px;
    color: white;
    .input_box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a {
      color: white;
    }
    ul {
      color: white;
    }
    &:nth-child(5) {
      display: none;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.search-container {
      input {
        width: 90%;
      }
    }

    &.search_icon {
      display: none;
      width: initial;
      position: absolute;
      top: calc(81% - 1px);
      right: 15px;
      padding: 0;
      display: flex;
      text-align: center;
      justify-content: flex-end;
      border: 0;
      color: white;
      div {
        display: none;
        width: 30px;
        height: 30px;
        font-size: 18px;
        color: white;
        border: 1px solid white;
      }
    }
    .sub_menu {
      // border: 3px solid var(--primary-color);
      position: initial;
      // top: 40px;
      // right: 0;
      a {
        border-bottom: 1px solid var(--primary-color);
        li {
          display: flex;
        }
      }
      width: 100%;
    }
    .search_input {
      position: absolute;
      right: 20%;
      top: 16px;
      a {
        color: black;
      }
    }
  }
  .search-container {
    padding: 10px 5px !important;
    padding-bottom: 5px !important;
  }
  .phone_number_container {
    padding-bottom: 5px;
    padding: 10px 5px;
    div {
      font-size: 10px;
      padding: 0 5px;
      border-right: 2px solid white;
    }
    .navbar_header {
      border-bottom: 0;
    }
  }
}
