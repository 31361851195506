.background_login {
  background: #b1a96e;
  height: 100vh;
  position: relative;
  span.anticon.anticon-eye {
    display: block !important;
  }
  form#basic {
    background-color: #ffffff;
    width: 600px;
    height: auto;
    border-radius: 5px;

    .login_form {
      padding: 30px 40px;
      width: 600px;
      position: absolute;
      top: 50%;
      left: 50%;
      background: white;
      transform: translate(-50%, -50%);

      #basic_remember {
        color: #606637;
      }

      button {
        background: #606637 !important;
        border-color: #606637 !important;
      }

      h1 {
        color: #606637;
        font-family: "Oswald", sans-serif;
        font-weight: 500;
      }
      .ant-row.ant-form-item {
        .ant-col.ant-col-8.ant-form-item-label {
          flex-grow: 0;
          justify-content: flex-start;
          display: flex;
        }
      }
      .ant-row,
      .ant-row:after,
      .ant-row:before {
        display: flex;
        flex-direction: column;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        content: "" !important;
      }

      label.ant-form-item-required {
        font-size: 15px;
        margin-top: 20px;
        font-weight: 400;
        color: #b8b8b8;
        font-family: "Oswald", sans-serif;
        letter-spacing: 2px;
      }

      .ant-row.ant-form-item-row {
        flex-direction: column;

        .ant-col.ant-col-8.ant-form-item-label {
          flex: 0 0 20% !important;
          text-align: initial;
          justify-content: center;

          .ant-form-item-label
            > label.ant-form-item-required:not(
              .ant-form-item-required-mark-optional
            ) {
            ::before {
              content: " ";
            }
          }
        }
      }

      .ant-col.ant-col-16.ant-form-item-control {
        margin-left: 70px;
      }

      input#basic_username {
        height: 50px;
        background-color: #e5e5e5;
        border-radius: 5px;
        font-family: "Oswald", sans-serif;
        font-size: 20px;
      }

      input#basic_password {
        height: 40px;
        background-color: #e5e5e5;
        font-family: "Oswald", sans-serif;
        font-size: 20px;

        svg {
          height: 23px;
        }
      }

      button.ant-btn.ant-btn-primary {
        width: 100%;
        font-family: "Oswald", sans-serif;
        height: 50px;

        span {
          font-size: 20px;
          font-family: "Oswald", sans-serif;
          font-weight: 400;
        }
      }

      label.ant-checkbox-wrapper {
        span {
          font-size: 15px;
          font-weight: 500;
          font-family: "Oswald", sans-serif;
          color: #b8b8b8;
        }
      }

      span.ant-input-affix-wrapper.ant-input-password {
        background-color: #e5e5e5;
        font-family: "Oswald", sans-serif;
        border-radius: 5px;

        span {
          span {
            svg {
              width: 23px;
              height: 30px;
            }
          }
        }
      }

      button.ant-btn.ant-btn-primary {
        font-family: "Oswald", sans-serif;
        border-radius: 5px;
      }

      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;

        a {
          font-size: 15px;
          font-family: "Oswald", sans-serif;
          font-weight: 400;
        }
      }

      .error {
        display: none;

        &.error_login {
          color: red;
          position: absolute;
          bottom: 2px;
          left: 72px;
          font-family: "Oswald", sans-serif;
          display: block;
        }
      }

      .Create_account {
        text-align: center;

        a {
          font: size 10px;
          font-weight: 600;
          font-family: "Oswald", sans-serif;
          color: #606637;
        }
      }
    }
  }
}

.ant-form-item-explain {
  font-family: "Oswald", sans-serif;
}
