/* --tablet-- */

@media (min-width: 740px) and (max-width: 1023px) {
  .footer_top {
    margin: 0;
    .footer_infor {
      padding: 0;
    }
  }
}
/* --Mobile-- */
@media (max-width: 740px) {
  .wrapper_footer {
    height: 100%;
  }
  .footer_top {
    margin: 0;
    padding: 0 30px;
    .footer_infor {
      padding: 0;
      flex-direction: column;
      .footer_title {
        h5 {
          margin-left: 10px;
        }
        .footer__contact {
          display: none;
        }
      }
    }
    .footer_contactmb {
      display: flex;
      .footer__contact {
        p {
          margin-left: 10px;
        }
        .footer_icon {
          font-size: 45px;
        }
      }
    }
  }
}
