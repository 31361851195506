.wrapper-item{
    margin-bottom: 40px;
    position: relative;
    width: 100%;
    .category-item{
        img{
            width: 100%;
            object-fit: cover;
        }
        .box-function{
            @keyframes decline {
                from {
                    height: 100%;
                }
                to{
                    height: 20%;
                }
            }
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 20%;
            background-color: rgba(149, 155, 105, 0.7);
            animation: decline 0.5s linear;
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 33px;
                text-align: center;
                text-transform: uppercase;
                color: white;
                
            }
            
        }
        &:hover{
            .box-function{
                @keyframes grow {
                    from {
                        height: 20%;
                    }
                    to{
                        height: 100%;
                    }
                }
                height: 100%;
                transform-origin: bottom;
                animation: grow 0.5s linear;
                text-align: center;
            }
        }
    }
}

@import 'ResponsiveMain-category.scss'