form#basic {
  background-color: #ffffff;
  height: auto;
  border-radius: 30px;

  .forgot_form {
    width: 600px;
    padding: 30px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: white;
    transform: translate(-50%, -50%);

    h1 {
      color: #606637;
      font-weight: 700;
    }

    p {
      color: #4e5053;
      font-weight: 700;
      margin-top: 30px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "" !important;
    }

    label.ant-form-item-required {
      font-size: 15px;
      margin-top: 20px;
      font-weight: 600;
      color: #b8b8b8;
      letter-spacing: 2px;
    }

    .ant-row.ant-form-item {
      flex-direction: column;

      .ant-col.ant-col-8.ant-form-item-label {
        flex: 0 0 20% !important;
        text-align: initial;
        margin-left: 70px;
        justify-content: center;
      }
    }

    .ant-col.ant-col-16.ant-form-item-control {
      margin-left: 70px;
      margin-bottom: 40px;
    }

    input#basic_email {
      height: 50px;
      background-color: #e5e5e5;
      font-size: 20px;
    }

    input::-webkit-input-placeholder {
      display: flex;
      align-content: center;
    }

    button.ant-btn.ant-btn-primary {
      width: 100%;
      height: 50px;
      margin-top: 1.5rem;
      background: #606637 !important;
      border-color: #606637 !important;

      span {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .email_error_notice {
    display: none;
  }

  .email_error {
    display: block;
    color: red;
    padding-left: 100px;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;

  a {
    span {
      svg {
        width: 30px;
        height: 25px;
        color: rgb(54, 51, 51);
      }
    }
  }
}