.nodata {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  .notData {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 30px;
    color: #cad09d;
  }
  span {
    width: 100px;
    font-family: "Saysettha";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    color: #62682b;
    text-align: center;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
  }
}
