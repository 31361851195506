@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}
.categories_table {
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
      height: 32px;
      padding: 7px;
      background: #606637;
      color: #f4f5f6;
      font-family: "Oswald", sans-serif;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      &:first-child {
        font-family: "Saysettha";
        font-weight: 700;
        font-size: 16px;
        font-family: "Oswald", sans-serif;
        line-height: 25px;
      }
      &:nth-child(2) {
        font-family: "Saysettha";

        text-align: center;
        font-weight: 400;
        font-size: 16px;
        font-family: "Oswald", sans-serif;
        line-height: 25px;
      }
      &:nth-child(3) {
        font-family: "Saysettha";

        font-family: "Oswald", sans-serif;
        text-align: center;
      }
    }
  }
  .ant-table-cell {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    p {
      font-family: "Oswald", sans-serif;
      svg {
        margin-right: 5px;
      }
    }
  }
  .operation_btn {
    display: flex;
    flex-direction: column;
    .ant-typography {
      &:last-child {
        font-family: "Oswald", sans-serif;
        color: #e70000;
      }
    }
  }
  li.ant-pagination-item {
    border: 0;
    background-color: #fff;
    font-family: "Oswald", sans-serif;
    color: #606637;
  }
  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    font-family: "Oswald", sans-serif;
    color: #fff;
  }
  button.ant-pagination-item-link {
    border: 0;
  }
  .ant-pagination-options {
    display: none;
  }
  .modal_wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    margin: 20px 0;
    button.ant-btn.ant-btn-primary {
      display: flex;
      align-items: center;
      text-align: center;

      height: 44px;
      background: #464932;
      border-radius: 10px;
      font-family: "Oswald", sans-serif;
      border: 0px;
      svg {
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 50%;
        font-family: "Oswald", sans-serif;
        margin-right: 20px;
      }
      span {
        font-family: "Saysettha";
        font-weight: 400;
        font-size: 20px;
        font-family: "Oswald", sans-serif;
        line-height: 32px;
      }
    }
  }
}
.ant-modal-wrap.modal_categories {
  // overflow: hidden;
  .ant-modal {
    // margin: 0px 345px !important;
    // width: 100% !important;
    // margin: 0px 200px;

    .ant-modal-content {
      width: 1025px;
      height: 100%;
      right: 120px;
      .ant-modal-header {
        .ant-modal-title {
          margin-left: 83px;
          font-family: "Saysettha";
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          text-transform: uppercase;
          color: #1c1f20;
          font-family: "Oswald", sans-serif;
        }
      }
      .ant-modal-body {
        width: 945px;
        height: 100%;
        margin-left: 80px;
        padding-top: 10px;
        h3 {
          margin-bottom: 19px;
          font-family: "Saysettha";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #1c1f20;
          font-family: "Oswald", sans-serif;
        }
        form {
          .noticeOff {
            position: absolute;
            margin-left: 162px;
            text-transform: uppercase;
            color: red;
            display: none;
            &.noticeOn {
              font-family: "Oswald", sans-serif;
              display: block;
            }
          }
          div {
            &:nth-child(5) {
              margin-left: 0;
              label {
                font-family: "Saysettha";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                color: #1c1f20;
                font-family: "Oswald", sans-serif;
              }
            }

            label {
              width: 72px;
              height: 25px;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #1c1f20;
              font-family: "Oswald", sans-serif;
              margin-right: 35px;
            }

            input {
              width: 470px;
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              padding-left: 10px;
              border: 0px;
              font-family: "Oswald", sans-serif;
            }
            .upload_image {
              background-color: transparent;
              padding-left: 0;
              margin-bottom: -24px;
            }
          }
          .ant-select.ant-select-single.ant-select-show-arrow {
            width: 470px !important;

            .ant-select-selector {
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 19px;
              padding: 8px 10px;
              font-family: "Oswald", sans-serif;
            }
            .ant-select-arrow {
              color: #000;
            }
          }
        }
      }
      .ant-modal-footer {
        display: flex;
        flex-direction: row-reverse;
        button.ant-btn.ant-btn-primary {
          width: 94px;
          height: 44px;
          left: 0px;
          top: 0px;
          background: #464932;
          border-radius: 10px;
          border: 0px;
          font-family: "Oswald", sans-serif;
        }
        button.ant-btn.ant-btn-default {
          width: 94px;
          height: 44px;
          border-radius: 10px;
          margin-left: 20px;
          border: 1px solid #464932;
          font-family: "Oswald", sans-serif;
        }
      }
    }
  }

  .ant-popover-message {
    display: flex;
    text-align: center;
    align-items: center;

    svg {
      color: red;
      font-size: 20px;
    }
    .ant-popover-message-title {
      color: red;
      font-size: 11px;
      padding-left: 10px;
    }
  }
  .ant-popover-buttons {
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      background: red;
      border: 0px;
      font-family: "Oswald", sans-serif;
      border-radius: 5px;
      :hover {
        color: rgb(168, 152, 179);
      }
    }
    button.ant-btn.ant-btn-default.ant-btn-sm {
      background: rgb(66, 66, 148);
      border: 0px;
      border-radius: 5px;
      font-family: "Oswald", sans-serif;
      color: white;
      :hover {
        background: rgb(50, 50, 149);
        color: rgb(168, 152, 179);
      }
    }
  }
}
span.ant-select-tree-switcher.ant-select-tree-switcher_open {
  display: none;
}
.ant-select-dropdown.ant-tree-select-dropdown.ant-select-dropdown-placement-topLeft {
  background: #d9d9d9;
}
.ant-select-tree-list {
  background: #d9d9d9;
}
.ant-select-tree-treenode.ant-select-tree-treenode-switcher-open {
  font-family: "Oswald", sans-serif;
  border-bottom: 1px solid #434242;
  // &:last-child {
  //   border-bottom: 0;
  // }
}
.ant-select-dropdown.ant-tree-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  background: #d9d9d9;
}
