@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}

.other_wrapper {
  margin: 30px 20px;
  .other_form {
    margin-bottom: 20px;
    margin-left: 50px;

    .input_data_other {
      margin-top: 30px;

      label {
        min-width: 90px;
        height: 25px;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        margin-right: 10px;
        font-family: "Oswald", sans-serif;
        flex: 0 0 100px;
      }
      input {
        width: 470px;
        height: 43px;
        font-family: "Oswald", sans-serif;
        background: #d9d9d9;
      }
      .input_des {
        font-family: "Oswald", sans-serif;
        height: 120px;
      }
      .other_key {
        width: 470px;
        height: 43px;
        background: #d9d9d9;
        display: flex;
        font-family: "Oswald", sans-serif;
        align-items: center;
        padding-left: 10px;
      }
    }
  }
  .submit_btn {
    width: 100%;
    display: flex;
    font-family: "Oswald", sans-serif;
    justify-content: flex-end;
    padding-right: 30px;
    padding-bottom: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 15px;
      font-family: "Saysettha";
      font-weight: 700;
      height: 50px;
      width: 90px;
      font-family: "Oswald", sans-serif;
      align-items: center;
      border: 0;
      background: #b1a96e;
      color: #fff;
      border-radius: 10px;
    }
  }
  form#form_otherAdmin {
    display: flex;
    font-family: "Oswald", sans-serif;
    flex-wrap: wrap;
  }
}
