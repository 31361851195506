.carousel-container-cls {
  width: 100%;
  padding: 0 50px;
  .ant-carousel {
    width: 100%;
  }

  .img-custom-cls {
    max-height: 480px;
    height: auto;
    width: 100%;
    object-fit: scale-down;
  }

  .box-conatiner {
    position: relative;
  }
  .text-cls {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    text-transform: uppercase;
    font-size: 20px;
    color: white;
    z-index: 100;
    background-color: gray;
    padding: 6px 10px;
    border-radius: 8px;
  }
}
