.subcription {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
  }
  span,
  p {
    font-size: 18px;
  }
}
.grid_image {
  position: relative;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 200px 90px 100px;
  gap: 10px;
}

.image_item:first-child {
  object-fit: cover;
  height: 200px;
  width: 100%;
  grid-column: 1/3;
  grid-row: 1/2;
}

.image_item:nth-child(2) {
  object-fit: cover;
  height: 300px;
  width: 100%;
  grid-column: 3/6;
  grid-row: 1/3;
}

.image_item:nth-child(3) {
  object-fit: cover;
  height: 200px;
  width: 100%;
  grid-column: 1/3;
  grid-row: 2/4;
}

.image_item:nth-child(4) {
  object-fit: cover;
  height: 100px;
  width: 100%;
  grid-column: 3/4;
  grid-row: 3/4;
}

.image_item:nth-child(5) {
  object-fit: cover;
  height: 100px;
  width: 100%;
  grid-column: 4/5;
  grid-row: 3/4;
}

.image_item:last-child {
  object-fit: cover;
  height: 100px;
  width: 100%;
  grid-column: 5/6;
  grid-row: 3/4;
}

.image_item-commited {
  width: 300px;
  object-fit: contain;
}

@import "about.replace.scss";
