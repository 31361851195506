@font-face {
  src: url("../../../assets/font/font_family/Saysettha-Regular.ttf");

  font-family: "Saysettha";
}
.news_table {
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
      height: 32px;
      padding: 7px;
      background: #606637;
      color: #f4f5f6;
      font-family: "Oswald", sans-serif;
    }
  }
  .ant-table-tbody {
    vertical-align: top;
    .ant-table-cell {
      &:first-child {
        font-family: "Saysettha";
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        font-family: "Oswald", sans-serif;
      }
      &:nth-child(2) {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        font-family: "Oswald", sans-serif;
      }
      &:nth-child(4) {
        color: #1c1f20;
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        height: 232px;
        line-height: 25px;
        font-family: "Oswald", sans-serif;

        p {
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "Oswald", sans-serif;
        }
      }
      &:nth-child(5) {
        font-family: "Saysettha";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
      }
    }
  }
  .ant-table-cell {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    font-family: "Oswald", sans-serif;

    p {
      font-family: "Oswald", sans-serif;

      svg {
        font-size: 16px;
        line-height: 25px;
        color: #1c1f20;
        margin-right: 5px;
      }
    }
  }
  .operation_btn {
    display: flex;
    flex-direction: column;
    font-family: "Oswald", sans-serif;
    .ant-typography {
      color: #1c1f20;
      &:nth-child(2) {
        color: #1890ff;
      }
      &:last-child {
        color: #e70000;
      }
    }
  }
  li.ant-pagination-item {
    border: 0;
    background-color: #fff;
    font-family: "Oswald", sans-serif;
    color: #606637;
  }
  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    font-family: "Oswald", sans-serif;
    color: #fff;
  }
  button.ant-pagination-item-link {
    font-family: "Oswald", sans-serif;
    border: 0;
  }
  .ant-pagination-options {
    display: none;
  }
  .modal_wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    margin: 20px 0;
    button.ant-btn.ant-btn-primary {
      display: flex;
      align-items: center;
      text-align: center;
      width: 214px;
      height: 44px;
      font-family: "Oswald", sans-serif;
      background: #464932;
      border-radius: 10px;
      border: 0px;
      svg {
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-right: 20px;
      }
      span {
        font-family: "Saysettha";
        font-weight: 400;
        font-size: 20px;
        font-family: "Oswald", sans-serif;
        line-height: 32px;
      }
    }
  }
}

.ant-modal-wrap.modal_news {
  .ant-modal {
    // margin: 0px 345px !important;
    width: initial !important ;
    margin: 0px 200px;

    .ant-modal-content {
      font-family: "Oswald", sans-serif;
      // width: 1025px;
      height: 100%;
      .ant-modal-header {
        .ant-modal-title {
          font-family: "Saysettha";
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          text-transform: uppercase;
          font-family: "Oswald", sans-serif;
          color: #1c1f20;
        }
      }
      .ant-modal-body {
        .input {
          font-family: "Oswald", sans-serif;
          position: relative;
          .titleInput {
            font-family: "Oswald", sans-serif;
            width: 100%;
          }
          .noticeOffEng {
            background-color: transparent !important;
            position: absolute;
            right: 0;
            text-transform: uppercase;
            color: red;
            font-family: "Oswald", sans-serif;
            display: none;
            &.noticeOnEng {
              font-family: "Oswald", sans-serif;
              display: block;
            }
          }
          .noticeOffLaos {
            background-color: transparent !important;
            position: absolute;
            right: 0;
            text-transform: uppercase;
            color: red;
            font-family: "Oswald", sans-serif;
            display: none;
            &.noticeOnLaos {
              display: block;
            }
          }
          .noticeFillOutOff {
            background-color: transparent !important;
            position: absolute;
            right: 0;
            text-transform: uppercase;
            color: red;
            font-family: "Oswald", sans-serif;
            display: none;
            &.noticeFillOutOn {
              font-family: "Oswald", sans-serif;
              display: block;
            }
          }
        }

        .noticeSumbitSuc {
          background-color: transparent !important;
          text-transform: uppercase;
          position: absolute;
          right: 30px;
          color: rgb(60, 60, 186);
          font-family: "Oswald", sans-serif;
          display: none;
          &.noticeSucOn {
            display: block;
          }
        }

        .noticeSumbitFail {
          background-color: transparent !important;
          text-transform: uppercase;
          position: absolute;
          right: 30px;
          color: rgb(209, 35, 35);
          font-family: "Oswald", sans-serif;
          display: none;
          &.noticeFailOn {
            display: block;
          }
        }

        // width: 945px;
        height: 100%;
        // margin-left: 80px;
        padding-top: 10px;
        h3 {
          margin-bottom: 19px;
          font-family: "Saysettha";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          font-family: "Oswald", sans-serif;
          color: #1c1f20;
        }
        div {
          div {
            &:nth-child(5) {
              font-family: "Oswald", sans-serif;
              margin-left: 0;
              label {
                font-family: "Saysettha";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                color: #1c1f20;
                width: 100%;
                font-family: "Oswald", sans-serif;
                margin-bottom: 20px;
                &:nth-child(1) {
                  font-family: "Saysettha";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 25px;
                  color: #1c1f20;
                  font-weight: 700;
                  font-size: 20px;
                  font-family: "Oswald", sans-serif;
                  line-height: 32px;
                }
              }
            }
            // &:last-child {
            //   margin-left: 0;
            // }
            label {
              width: 90px;
              height: 25px;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #1c1f20;
              font-family: "Oswald", sans-serif;
              margin-right: 35px;
              :first-child {
                width: 100px;
              }
            }
            .quill {
              .ql-toolbar {
                background-color: #b1a96e;
              }
              .ql-container {
                .ql-editor {
                  border: 1px black;
                  width: 100%;
                  height: 300px;
                  font-family: "Saysettha";
                  background-color: #ebe8e8 !important;
                  font-family: "Oswald", sans-serif;
                  line-height: 32px;
                  color: #1c1f20;
                  padding-left: 20px;
                }
              }
            }
            input {
              width: 470px;
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-family: "Oswald", sans-serif;
              font-size: 16px;
              line-height: 25px;
              padding-left: 10px;
              border: 0px;
            }
          }
          .ant-select.ant-select-single.ant-select-show-arrow {
            width: 470px !important;

            .ant-select-selector {
              height: 43px;
              background-color: #d9d9d9;
              color: #1c1f20;
              font-family: "Saysettha";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 19px;
              font-family: "Oswald", sans-serif;
              padding: 8px 10px;
            }
            .ant-select-arrow {
              right: -15px;
              color: #000;
            }
          }
        }
        textarea {
          height: 120px;
          width: 865px;
          background: #d9d9d9;
          font-family: "Oswald", sans-serif;
          border: 0px;
        }
        .view_page {
          height: 720px;
          display: flex;
          flex-direction: column;
          font-family: "Oswald", sans-serif;
          justify-content: center;
          align-items: center;
          img {
            width: 400px;
            height: auto;
          }
          p {
            margin: 0 20px;
            margin-top: 15px;
            display: block;
            height: 500px;
            overflow: auto;
            font-family: "Saysettha";
            font-style: normal;
            font-weight: 400;
            font-size: 16.0557px;
            line-height: 25px;
            font-family: "Oswald", sans-serif;
            color: #1c1f20;
            &::-webkit-scrollbar {
              width: 0 !important;
              display: none;
            }
          }
        }
      }
      .ant-modal-footer {
        display: flex;
        flex-direction: row-reverse;
        button.ant-btn.ant-btn-primary {
          width: 94px;
          height: 44px;
          left: 0px;
          top: 0px;
          font-family: "Oswald", sans-serif;
          background: #464932;
          border-radius: 10px;
          border: 0px;
        }
        button.ant-btn.ant-btn-default {
          width: 94px;
          height: 44px;
          border-radius: 10px;
          margin-left: 20px;
          border: 1px solid #464932;
          font-family: "Oswald", sans-serif;
        }
      }
    }
  }
}
span.ant-select-tree-switcher.ant-select-tree-switcher_open {
  font-family: "Oswald", sans-serif;
  display: none;
}
.ant-popover-message {
  display: flex;
  text-align: center;
  font-family: "Oswald", sans-serif;
  align-items: center;

  svg {
    color: red;
    font-family: "Oswald", sans-serif;
    font-size: 20px;
  }
  .ant-popover-message-title {
    color: red;
    font-size: 11px;
    font-family: "Oswald", sans-serif;
    padding-left: 10px;
  }
}
.ant-popover-buttons {
  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background: red;
    border: 0px;
    font-family: "Oswald", sans-serif;
    border-radius: 5px;
    :hover {
      color: rgb(168, 152, 179);
    }
  }
  button.ant-btn.ant-btn-default.ant-btn-sm {
    background: rgb(66, 66, 148);
    border: 0px;
    border-radius: 5px;
    font-family: "Oswald", sans-serif;
    color: white;
    :hover {
      background: rgb(50, 50, 149);
      color: rgb(168, 152, 179);
    }
  }
}

.page-controller_News_admin {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  font-family: "Oswald", sans-serif;

  li.ant-pagination-item {
    border: 0;
    border-radius: 0 !important ;
    background-color: #fff;
    color: #606637;
    font-family: "Oswald", sans-serif;
  }

  li.ant-pagination-item-active {
    border: 0;
    background-color: #606637;
    color: #fff;
    font-family: "Oswald", sans-serif;
    border-radius: none;
  }

  li.ant-pagination-prev .ant-pagination-item-link,
  li.ant-pagination-next .ant-pagination-item-link {
    border: none;
    font-family: "Oswald", sans-serif;
    border-radius: none;
  }
}
