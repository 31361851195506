:root {
  // color

  --primary-color: #606637;

  // Layout
  --deafault-layout-margin: 0px 107px;
  --deafault-layout-padding: 0 calc(50% - 1280px / 2);
}

a[href] {
  text-decoration: none;
}

p {
  // font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */
  color: #000000;
  margin-bottom: 0;
}

img {
  max-width: 100%;
  width: auto;
}

.wrapper {
  position: relative;
  margin: 40px 0;
}

.main_title p {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #464932;
  text-transform: uppercase;
  text-align: center;
  &.custom-family {
    font-family: "Oswald", sans-serif;
    font-weight: 100;
  }
}

.main_title {
  &.custom-family-news {
    font-family: "Oswald", sans-serif;
    font-size: 25px;
    font-weight: 600;
    p {
      font-size: 25px;
    }
  }
}

.title {
  padding-top: 20px;
  font-family: "Oswald", sans-serif;
}

.title p {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 27px;
  /* identical to box height */
  color: #606637;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}

.title-item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
  color: black;
  margin-bottom: 15px;
  font-family: "Oswald", sans-serif;
}

.subcription {
  margin-bottom: 25px;
  font-family: "Oswald", sans-serif;
}

.ant-form-item-label > label:after {
  display: none;
}
