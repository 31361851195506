@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper_product_list {
    height: 100%;
    .body_prodList {
      .product {
        margin-top: 10px;
        flex-direction: column;
        width: initial;
        .product_img {
          margin-bottom: 60px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper_product_list {
    height: 100%;
    .header_prodList {
      h2 {
        font-size: 28px;
      }
    }
    .body_prodList {
      .list_product {
        .header_product {
          .product_name {
            .item_parent {
              min-width: 320px;
              width: 320px;
              &.classParent {
                min-width: 340px;
                width: 340px;
              }
            }
          }
        }
      }
      .product {
        flex-direction: column;
        margin-top: 30px;
        width: auto;
        .product_img {
          width: initial;
          margin-bottom: 80px;
          margin-left: 0;
          div {
            width: initial;
            height: initial;
            img {
              width: 100%;
              margin: 0 10px;
            }
          }

          a {
            div {
              width: 100%;
            }
          }
          &:first-child {
            margin-left: 0;
          }
        }
        p {
          // width: initial;
        }
      }
    }
  }
}
