@media (min-width: 992px) and (max-width: 1023px) {
  .wrapper-item {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 740px) {
  .wrapper-item {
    width: initial;
    height: initial;
  }
}
