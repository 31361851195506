@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper_prodetail {
    .content_product_detail {
      .content_img {
        width: 400px;
        .img_view {
          height: initial;
          width: initial;
          img {
          }
        }
        .img_selection {
          .list-image {
            div {
              .img_select {
                width: 86px;
                height: 115px;
                margin: 8px 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper_prodetail {
    .content_product_detail {
      flex-direction: column;
      .content_img {
        width: 100%;
        // height: 580px;
        .img_view {
          height: initial;
          width: initial;
          img {
            // width: 400px;
          }
        }
        .img_selection {
          .list-image {
            margin-left: 5px;
            margin-right: 5px;
            //display: none;
            div {
              .img_select {
                width: 90px;
                height: 90px;
                margin: 10px 2px;
              }
            }
          }
        }
      }
    }
  }
}
